<template>
  <v-container>
    <v-row class="text-center">
      <!-- <v-col cols="12">
        <v-img
          :src="require('../assets/rynenation-logo-dark-large.png')"
          contain
          width="200"
        />
      </v-col> -->

      <v-col class="mb-4">
        <header>
            <h1><i class="fa fa-check-circle" id="checkmark" style="color:green"></i></h1>
            <h1 class="site-header__title" data-lead-id="site-header-title">THANK YOU FOR YOUR PAYMENT!</h1>
        </header>


      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
    }),
  }
</script>
