<template>
  <v-container>
    <v-layout row justify-center>
            <v-dialog v-model="is_loading" persistent fullscreen content-class="loading-dialog" transition="none">
              <v-container fill-height>
              <v-layout row justify-center align-center>
                <v-progress-circular v-if="is_loading" indeterminate :size="80" color="primary" class="mx-2" style="position:fixed;bottom:50%;left:50%"></v-progress-circular>
              </v-layout>
              </v-container>
            </v-dialog>
            <v-dialog v-model="is_processing" persistent fullscreen content-class="loading-dialog" transition="none">
              <v-container fill-height>
              <v-layout row justify-center align-center>
                <v-progress-circular v-if="is_processing" indeterminate :size="80" color="primary" class="mx-2" style="position:fixed;bottom:50%;left:50%"></v-progress-circular>
              </v-layout>
              </v-container>
            </v-dialog>
    </v-layout>
    
    <v-row v-if="!is_loading">
      <v-col class="mb-4" cols="12" v-if="is_valid_link">
        <v-card >
          <v-card-title  class="blue--text font-weight-black title" >支付</v-card-title>
          <v-card-text class='pb-10'>
            <v-row>
              <v-col cols="12">
                <v-subheader class="pl-0 subheader">參賽主題</v-subheader>
                <h1>{{payment_details.product_name}}</h1>
              </v-col>
              <v-col cols='12'>
                <v-subheader class="pl-0 subheader">支付金額 ($)</v-subheader>
                <h1>HKD$ {{payment_details.amount}}</h1>
              </v-col>
              <v-col cols="12">
                <v-subheader class="pl-0 subheader">信用卡/借記卡</v-subheader>
                <div class="payment-simples">
                  <StripeElements :stripe-key="stripeKey"
                    :instance-options="instanceOptions"
                    :elements-options="elementsOptions" #default="{ elements }"  ref="elms">
                    <StripeElement
                      type="card" :elements="elements"
                      :options="cardOptions"
                      ref="card"
                    />
                  </StripeElements>
                  <!-- <button @click="pay" type="button">Pay</button> -->
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn block outlined color="#0277BD" style="font-size:18px" @click="makePayment">付款</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
        
      </v-col>
      <v-col class="mb-4 text-center" v-else  >
        <h1 class="display-1 font-weight-bold mb-3 red--text">
          Invalid Payment Link!
        </h1>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
  import {api} from '@/config/apiUrls'
  import axios from 'axios'
  export default {
    data: () => ({
      is_valid_link:false,
      is_processing:false,
      is_loading:false,
      payment_amount:"",
      payment_details:{},
      //stripeKey: 'pk_test_51KR4RoIJrPAXlltdonWVhg6Qdumg1WpijyKQCvERuHj5FMNoZHxLgOPRJ0A9JC0UJc1rSNgbYvLZmnx8oMbVA8AU00SHjcxLgV',
      // HKEDA Live Key
      //stripeKey: 'pk_live_51KR4RoIJrPAXlltd9VZF77ejkkhLNafDwVG6jSmGb41knEiNmYxlbH7tJ9fgsURaRtTsW603RQlbhA5e33vyHYrP00gVkOq9Cq',
      // Demo Test Key
      stripeKey: 'pk_test_51H7IhkDrC4wRnL9B88E7PR0PWqlJJrTPqAJkmuiIOpmRMTSifN7q1k6b61EPAU9rqM6wdnX9MonkPtfW0syrhdub00m7MuPsdR',
      //stripeKey: 'pk_live_51IiywiImnQl8gl6LqZ8u6Me43Wvhc1QEGdusYZIK2sQOx6k0MINJxW18VxfkuebtiGgNDsKxh0Kppney98u3dlJO00XPC3QXAV',
      instanceOptions: {
      },
      elementsOptions: {
      },
      cardOptions: {
        hidePostalCode: true
      },
    }),
    components: {
      StripeElements,
      StripeElement,
    },
    async mounted()
    {
      this.is_loading = true
      let hash_key = this.$route.params.id;
      await this.retrievePaymentInformation(hash_key)
      this.is_loading = false
    },
    methods:
    {
        async retrievePaymentInformation(hash_key)
        {
            await axios.post(api['GET_PAYMENT_LINK_DETAILS'], {hash_key:hash_key}).then(res => 
            {
                  if (res.data.code == 1)
                  {
                    this.payment_details = res.data.data
                    this.is_valid_link = true
                  }
                  else
                  {
                    this.is_valid_link = false
                    //alert(res.data.msg)
                  }
           })
        },
        async confirmPayment(payload)
        {
          await axios.post(api['CHARGE_PAYMENT'], payload).then(res => 
          {
              if (res.data.code == 1)
              {
                this.$router.push({ name: 'ThankYouPage'})
              }
              else
              {
                alert(res.data.msg)
              }
          })
        },
        async makePayment()
        {
          if(this.payment_details.amount != '')
          {
              this.is_processing = true
              const groupComponent = this.$refs.elms
                const cardComponent = this.$refs.card
                // Get stripe element
                const cardElement = cardComponent.stripeElement

                // Access instance methods, e.g. createToken()
                groupComponent.instance.createToken(cardElement).then(result => {
                  if (result.error) {
                    alert(result.error.message)
                  }
                  else
                  {                      
                      let payload = {stripe_response:result, payment_details:this.payment_details}
                      axios.post(api['CHARGE_PAYMENT'], payload).then(res => 
                      {
                          if (res.data.code == 1)
                          {
                            this.$router.push({ name: 'ThankYouPage'})
                          }
                          else
                          {
                            alert(res.data.msg)
                          }
                      })
                  }
                  
                })


          }
          else
          {
            alert("Payment Amount Required!")
          }

          // this.is_processing = false

        },
    }
  }
</script>
