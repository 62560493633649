<template>
   <v-select
     v-model="selected"
     :item-disabled="disableItem"
     :items="items"
   />
</template>
<script>
export default {
  data () {
    return {
      selected: ['name'],
      items: [
        {
          text: 'Nome de A à Z',
          value: 'name'
        },
        {
          text: 'Nome de Z à A',
          value: '-name'
        },
        {
          text: 'Mais recente à mais antiga',
          value: '-updated_at'
        },
        {
          text: 'Mais antiga à mais recente',
          value: 'updated_at'
        }
      ]
    }
  },
  methods: {
    disableItem (item) {
      let invertedValue

      if (item.value.match(/^-/)) {
        invertedValue = item.value.replace(/^(-)/, '')
      } else {
        invertedValue = '-' + item.value
      }

      return this.selected.includes(invertedValue)  
    }
  }
}
</script>