<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img alt="Payment" class="shrink mr-2" contain src="./rynenation-logo-light-large.png" transition="scale-transition" width="180"/>
      </div>
    </v-app-bar>
    <v-main>
        <v-content transition="slide-x-transition">
            <router-view></router-view>
        </v-content>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  components: {
    // HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
