export const apiBaseUrl = 'https://api.hkeda.unityerp.com/'
export const apiDemoBaseUrl = 'https://api.unityerp.com/demo/'

export const api = 
{
    CHARGE_PAYMENT:apiBaseUrl + 'messagebird/charge_payment',
    GET_PAYMENT_LINK_DETAILS:apiBaseUrl + 'messagebird/get_payment_link_details',
    GET_SELECTION_LINK_DETAILS:apiBaseUrl + 'whatsapp/get_selection_details',
}

export const apiDemo = 
{
    CHARGE_PAYMENT:apiDemoBaseUrl + 'messagebird/charge_payment',
    GET_PAYMENT_LINK_DETAILS:apiDemoBaseUrl + 'messagebird/get_payment_link_details',
    GET_SELECTION_LINK_DETAILS:apiDemoBaseUrl + 'whatsapp/get_selection_details',
}