import Vue from 'vue';
import Router from 'vue-router';
import Home from './components/HelloWorld.vue';
import Payment from './components/payment.vue';
import PaymentDemo from './components/payment.demo.vue';
import Selection from './components/Selection.vue';
import ThankYouPage from './components/thankYou.vue';
import test from './components/test.vue';

Vue.use(Router);
export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/',
            redirect: '/about'
        },
        {
            path: '/demo/:id',
            name: 'paymentDemo',
            component: PaymentDemo
        },
        {
            path: '/pay/:id',
            name: 'payment',
            component: Payment
        },
        {
            path: '/sens/selection/:id',
            name: 'selection',
            component: Selection
        },
        {
            path: '/thank-you',
            name: 'ThankYouPage',
            component: ThankYouPage
        },
        {
            path: '/about',
            name: 'about',
            component: Home
        },
        {
            path: '/test',
            name: 'test',
            component: test
        }        
    ]
});